@font-face {
    font-family: 'Montserrat';
    src:  url('/fonts/montserrat/static/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 100 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src:  url('/fonts/montserrat/static/Montserrat-Medium.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Evolventa';
    src:  url('/fonts/evolventa/Evolventa-Regular-BF63c0b29a31dbb.ttf') format('truetype');
    font-weight: 100 800;
    font-style: normal;
}

@font-face {
    font-family: 'Evolventa';
    src:  url('/fonts/evolventa/Evolventa-Bold-BF63c0b29a3afd5.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

:root{
    --main-font--: 'Evolventa';
    --secondary-font--: 'Montserrat';
    --main-font-color--: black;
    --main-color--: #004aad;
    --secondary-color-blue--: #99c7e7;
    --secondary-color-beige--: #ffefdd;
    --font-size-medium--: clamp(.75rem, 2vw, 1.25rem);
    --font-size-x-medium--: clamp(.6rem, 2vw, 1.25rem);
    --font-size-small--: clamp(.5rem, 1vw, .75rem);
    --font-size-big--: clamp(1rem, 3vw, 1.75rem);
    --font-size-x-big--: clamp(1.25rem, 3vw, 2.5rem);
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body{
    background: linear-gradient(90deg,  rgb(24, 114, 224), #b3b3ff);
    font-family: var(--main-font--);
}

::-webkit-scrollbar{
    width: 1rem;
    background-color: #e0e0ff;
}

::-webkit-scrollbar-button{
    display: none;
}

::-webkit-scrollbar-thumb{
    height: 2rem;
    border-radius: 20px;
    background-color: rgb(24, 114, 224);
    transition: all .3s;
    cursor: pointer;
}

::-webkit-scrollbar-thumb:hover{
    background-color: rgb(22, 103, 202);
}

a{
    text-decoration: none;
    color: var(--main-font-color--);
}

.navbar{
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    font-family: var(--main-font--);
    width: 100%;
    justify-content: space-between;
    padding: clamp(.5rem, 2vw, 1.5rem);
    align-items: center;
    background-color: transparent;
    z-index: 15;
    max-width: 100vw;
    animation: stickyNav linear forwards 1s;
    @supports (animation-timeline: view()){
        animation-timeline: view();
        animation-range-start: 100vh;
        animation-range-end: 120vh;
    }
}

@keyframes stickyNav {
    100%{
        background-color: hsl(0 50% 90% / .2);
        backdrop-filter: blur(0.2rem);
    }
}

.navbar > h2{
    font-size: var(--font-size-big--);
    word-spacing: .5rem;
    white-space: nowrap;
    display: inline-block;
    max-width: 50%;
}

.navbar-menu{
    position: absolute;
    display: inline-flex;
    gap: 2rem;
    right: 0;
    padding-top: .5rem;
    max-width: 50%;
}

@supports (display: flex) {
    .navbar-menu {
        position: relative;
    }
}   

.navbar-menu > li > a, .navbar-menu__burger-container > li > a{
    position: relative;
    text-decoration: none;
    color: var(--main-font-color--);
    list-style: none;
    font-size: var(--font-size-medium--);
}

.navbar-menu__burger-container > li > a{
    font-size: var(--font-size-big--);
}

.navbar-menu > li,  .navbar-menu__burger-container > li{
    position: relative;
    text-decoration: none;
    color: var(--main-font-color--);
    list-style: none;
}

.navbar-menu > li:not(:first-of-type):before{
    position: absolute;
    content: '';
    width: .1rem;
    height: 100%;
    background-color: var(--main-font-color--);
    left: -17%;
}

.navbar-menu > li:nth-of-type(3):before{
    /* left: -43%; */
    left: -18%;
}

.navbar-menu > li:nth-of-type(4):before{
    left: -42%;
}

.navbar-menu > li > a::after{
    position: absolute;
    content: '';
    width: 100%;
    height: .15rem;
    background-color: var(--main-color--);
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
}

.navbar-menu > li > a:hover::after{
    transform: scaleX(1);
    transform-origin: left;
}

.navbar-menu > li > a:hover{
    cursor: pointer;
}

.navbar-menu__burger-container{
    display: none;
    position: fixed;
    flex-direction: column;
    gap: 2rem;
    z-index: 4;
    top: 0;
    right: -100%;
    width: 100vw;
    height: 100vh;
    background-color: white;
    justify-content: center;
    align-items: center;
    transition: right .8s;
}

.tyson-image-navbar{
    aspect-ratio: 1,23 / 1;
    width: 2rem;
}

.navbar > h2 > a{
    display: flex;
    align-items: center;
    gap: .75rem;
}

.navbar > h2, .navbar-menu {
    flex-shrink: 0; /* Забороняє зменшувати ширину */
    min-width: 0; /* Гарантує, що елементи не розтягуються на новий рядок */
}

.burger-menu-button{
    display: none;
    flex-direction: column;
    gap: .25rem;
    z-index: 10;
    right: 0;
    top: 0;
    max-width: 50%;
}

.menu-toggle {
    display: none;
}

.burger-menu-button > span{
    background-color: black;
    width: 1.3rem;
    height: .2rem;
    border-radius: 20px;
    transition: all .5s;
}

.menu-toggle:checked + .burger-menu-button span:nth-child(1) {
    transform: translateY(7px) rotate(405deg);
}
  
.menu-toggle:checked + .burger-menu-button span:nth-child(2) {
    opacity: 0;
}
  
.menu-toggle:checked + .burger-menu-button span:nth-child(3) {
    transform: translateY(-7.5px) rotate(-405deg);
}

.menu-toggle:checked ~ .navbar-menu__burger-container {
    display: flex;
    right: 0;
}

.navbar-care-service{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: clamp(1rem, 1.5vw, 5rem);
    font-size: clamp(.5rem, 3vw, 1.75rem);
    background-color: #dde9fe;
    padding: 10px 20px;
    margin: 10px 0 0 0;
}

.care-service-relative{
    position: relative;
    margin: clamp(2rem, 4vw, 4rem) 0 0 0;
}

.main-container{
    max-width: 100vw;
    min-height: 100dvh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: var(--font-size-medium--);
    padding: clamp(3rem, 5vw, 5rem) clamp(.75rem, 5vw, 5rem) 0 clamp(.75rem, 5vw, 5rem);
    position: relative;
}

.name-container{
    display: flex;
    flex-direction: column;
    gap: clamp(.5rem, 1vw, 1rem);
}

.course-1-cta-container{
    background-color: #eee0d0;
    display: flex;
    padding: clamp(1rem, 1.5vw,1.5rem);
    width: 75%;
    gap: 1rem;
    flex-direction: column;
    border-radius: 20px;
    justify-content: center;
    font-size: var(--font-size-x-medium--);
    text-align: left;
}

.columns{
    flex-direction: column;
    text-align: center;
}

.columns > div{
    flex-direction: column;
    justify-items: center;
}

.whitesmoke{
    background-color: whitesmoke;
}

.course-1-cta-container > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.25rem;
}

.course-1-cta-img{
    width: clamp(8rem, 35%, 15rem);
    display: block;
    border-radius: 20px;
}

.wholescreen{
    width: 85%;
    margin: 0 auto clamp(.5rem, 1vw, 1rem) auto;
    display: flex;
    justify-content: center;
}

.wholescreen > div > img{
    width: clamp(8rem, 45%, 18rem);
    display: block;
    border-radius: 20px;
}

.course-1-cta-container > a > button{
    font-size: var(--font-size-medium--);
    padding: clamp(.5rem, 1vw ,1rem);
    border-radius: 20px;
    border: none;
    min-width: 50%;
    cursor: pointer;
    background-color: var(--secondary-color-blue--);
    transition: all .4s;
    font-family: var(--secondary-font--);
}

.course-1-cta-container > a > button:hover{
    background-color: #90b9d6;
}

.name-container__small{
    display: flex;
    flex-direction: column;
    gap: clamp(.5rem, 1vw, 1rem);
}

.name-container > div > p{
    font-size: clamp(1rem, 2vw, 1.75rem);
}

.name-container > div > h1{
    font-size: clamp(1.2rem, 3vw, 2.5rem);
}

.main-container > img{
    align-self: flex-end;
    aspect-ratio: 1,27 / 1;
    width: clamp(11.5rem, 32vw, 31rem);
}


@media (max-width: 1000px) {
    .main-container{
        flex-direction: column-reverse;
        align-items: center;
        justify-items: center;
        flex-wrap: wrap;
        gap: .25rem;
    }
    .name-container{
        align-items: center;
        text-align: center;
        margin-bottom: 1rem;
    }
    .name-container > div{
        display: flex;
        justify-content: center;
    }
    .course-1-cta-container > div{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
    }
    .wholescreen > a > button{
        min-width: 100%;
    }
    .course-1-cta-img{
        order: 1;
    }
    .course-1-cta-container > div > div{
        order: 2;
    }
    .main-container > img{
        align-self: center;
        margin-top: 1rem;
    }
    .name-container > a > button{
        width: 90%;
    }
    .name-container > a {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .navbar{
        flex-wrap: nowrap;
    }
    .navbar-menu__burger-container{
        display: flex;
    }
    .burger-menu-button{
        display: flex;
    }
    .navbar-menu{
        display: none;
    }
    .course-1-cta-container{
        margin-bottom: 1rem;
    }
}

.social-network-line-promo{
    position: relative;
    width: 100%;
    background-color: var(--secondary-color-blue--);
    height: clamp(5rem, 10vw, 10rem);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: clamp(4rem, 9vw, 8rem);
}

.small-margin{
    margin-top: clamp(2rem, 9vw, 8rem);
}

.social-network-line-promo-image{
    aspect-ratio: calc(3024 / 4032);
    width: clamp(8rem, 16vw, 13.5rem);
    align-self: flex-end;
}

.social-network-line-promo > div{
    display: flex;
    flex-direction: column;
    padding: clamp(.75rem, 2vw, 2rem);
    gap: clamp(.75rem, 2vw, 1rem);
    font-size: clamp(.8rem, 2vw, 1.25rem);
    align-items: center;
}

@media (max-width: 375px) {
    .social-network-line-promo-image{
        width: clamp(7rem, 16vw, 13.5rem);
    }
    .social-network-line-promo > div{
        font-size: clamp(.85rem, 2vw, 1.25rem);
    }
    .social-network-line-promo h2{
        font-size: clamp(1.1rem, 2vw, 1.25rem);
    }
}

.social-network-line-promo > div > a{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .75rem;
    padding: clamp(.5rem, 1vw, 1rem);
    background-color: var(--secondary-color-beige--);
    border-radius: 20px;
    border: none;
    cursor: pointer;
    transition: all .3s;
    font-family: var(--secondary-font--);
    text-decoration: none;
    color: black;
}

.social-network-line-promo > div > a:hover{
    background-color: #eee0d0;
}

.footer{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    margin: 2rem 0;
    gap: 1rem;
    padding: 0 clamp(.75rem, 2vw, 2rem);
}

.footer-important-links{
    display: flex;
    gap: clamp(.75rem, 2vw, 2rem);
    text-decoration: underline;
    font-size: clamp(.9rem, 2.5vw, 1.5rem);
}

.footer-image{
    aspect-ratio: 1;
    width: 5rem;
    border-radius: 10px;
}

.footer-copyright{
    display: flex;
    align-items: center;
    gap: .5rem;
    font-size: clamp(.85rem, 3vw, 1.5rem);
    font-family: var(--main-font--);
}

.footer-copyright > div{
    display: flex;
    align-items: center;
    gap: .5rem;
}

@media (max-width: 620px) {
    .footer-important-links{
        flex-direction: column;
        text-align: center;
    }
    .footer-copyright{
        flex-direction: column;
    }
}


.privacy-policy-container, .refund-policy-container, .contract-form-container{
    padding: clamp(4rem, 10vw, 6rem) clamp(3rem, 3vw, 3rem) 0 clamp(3rem, 3vw, 3rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100dvw;
    gap: clamp(2rem, 6vw, 4rem);    
    text-align: center;
}

.refund-policy-container{
    gap: clamp(1rem, 2vw, 2rem); 
    padding: clamp(4rem, 10vw, 6rem) clamp(1rem, 3vw, 3rem) 0 clamp(1rem, 3vw, 3rem);   
}

.privacy-policy-container p,  .contract-form-container p, .refund-policy-container p{
    display: flex;
    flex-direction: column;
    gap: clamp(1.5rem, 3vw, 3rem);
    font-size: var(--font-size-medium--);
}

.privacy-policy-container h1,  .contract-form-container h1, .refund-policy-container h1{
    font-size: var(--font-size-big--);
}

.contract-form-container div{
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 1rem;
}

.contract-form-container div p:first-child{
    text-align: center;
}