.main-container-course-1{
    padding: clamp(4rem, 10vw, 6rem) clamp(2rem, 3vw, 3rem) 0 clamp(2rem, 3vw, 3rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100dvw;
    gap: clamp(2rem, 6vw, 4rem);
}

.small-padding{
    padding: clamp(2rem, 10vw, 5rem) clamp(2rem, 3vw, 3rem) 0 clamp(2rem, 3vw, 3rem);
}

.main-container-course-1 > div{
    width: 100%;
}

.header-container-course-1{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 100vw;
    position: relative;
    gap: clamp(.5rem, 1vw, 1rem);
    background-color: var(--secondary-color-beige--);
    padding: clamp(.75rem, 2vw, 2rem);
    border-radius: 20px;
}

.header-container-paragraph{
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: clamp(.5rem, 1vw, 1rem);
    padding: clamp(.75rem, 2vw, 2rem);
}

p, li{
    font-size: clamp(0.95rem, 2vw, 1.7rem);
}

a button{
    color: black;
    text-align: center;
    padding: 2rem;
    width: 50%;
    text-decoration: none;
    border-radius: 20px;
    font-size: var(--font-size-big--)!important;
    padding: 1rem;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    background-color: var(--secondary-color-beige--);
    transition: all .3s;
    font-family: var(--secondary-font--);
}

a button:hover{
    background-color: #eee0d0;
}

.course-1-container, .course-1-container-courselist, .course-2-container-courselist{
    background-color: var(--secondary-color-beige--);
    display: flex;
    padding: clamp(1.5rem, 2vw, 2rem);
    width: 75%;
    gap: clamp(.75rem, 1.5vw, 1.5rem);
    flex-direction: column;
    border-radius: 20px;
    align-items: center;
    font-size: var(--font-size-medium--);
}

.course-2-container-courselist{
    padding: clamp(1.5rem, 2vw, 2rem) clamp(1.5rem, 10vw, 10rem);
}

.text-center-align{
    text-align: center;
}

.course-1-container-courselist{
    justify-content: center;
}

h2{
    font-size: clamp(1.2rem, 1.7vw, 1.7rem);
}

h1{
    font-size: clamp(1.4rem, 1.8vw, 1.8rem);
}

.blue{
    background-color: var(--secondary-color-blue--);
}

.course-1-courselist, .course-1-program{
    display: flex;
    gap: clamp(.75rem, 1.5vw, 1.5rem);
    flex-direction: column;
}


.course-1-courselist h2{
    text-align: center;
}

.course-1-for-whom{
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
    gap: clamp(1.5rem, 2.5vw, 2.5rem);
    flex-wrap: wrap; 
}

.course-1-for-whom > div{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: clamp(.75rem, 1.5vw, 1.5rem);
    align-content: center;
    text-align: center;
    font-size: var(--font-size-x-medium--);
    flex: 1;
    min-width: 10rem;
}

a:has(button){
    width: 100%;
    display: flex;
    justify-content: center;
}

.course-1-program {
    counter-reset: programCounter; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.course-2-program{
    counter-reset: none;
}

.course-1-program > h2::before {
    content: counter(programCounter, decimal-leading-zero); 
    counter-increment: programCounter;
    color: var(--main-color--);
    margin-right: 0.5rem;
}

.course-2-program > h2::before{
    content: none;
}

.course-1-program > p {
    margin-left: 2.25rem;
    counter-increment: subCounter;
}

.course-1-program > h2 + p{
    counter-reset: subCounter;
}

.course-1-program > p::before {
    content: counter(programCounter, decimal) "." counter(subCounter, decimal);
    color: var(--main-color--);
    margin-right: 0.5rem;
}

.course-2-program > p::before {
    content: none;
}

.course-2-program > div, .course-2-courselist > div{
    display: flex; 
    align-items: center;
    gap: .75rem;
}

.course-1-benefits{
    display: grid;
    grid-template-columns: repeat(2, minmax(20rem, 1fr));
    row-gap: clamp(2rem, 4vw, 4rem);
    column-gap: 2rem;
}

@media (max-width: 700px) {
    .course-1-benefits{
        place-items: center;
        grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    }
}

button.blue:hover{
    background-color: #90b9d6;
}

.course-1-benefits > div{
    display: flex;
    justify-content: center;
    gap: clamp(.75rem, 1.5vw, 1.5rem);
    flex-direction: column;
}

.container-course-1-benefit{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: clamp(1rem, 1.5vw, 1.5rem);
    text-align: center;
    flex: 1;
}

.header-container-course-1 > .header-container-paragraph > h1{
    text-align: center;
}