.reviews-wrapper {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
}
  
.reviews-container {
    width: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
  
.reviews-header {
    text-align: center;
    margin: clamp(1rem, 2vw, 2rem) 0;
    background-color: var(--secondary-color-beige--);
    padding: clamp(.5rem, 2vw, 2rem);
    border-radius: 20px;
}
  
.reviews-slider {
    padding: 1rem 0;
    margin: 0rem 0;
    display: flex;
    align-items: center;
    gap: clamp(.75rem, 2vw, 2rem);
    overflow-x: hidden;
    scroll-behavior: auto;
    user-select: none;
    mask-image: linear-gradient(
        to right,
        rgba(0,0,0,0),
        rgba(0,0,0,1) 10%,  
        rgba(0,0,0,1) 90%,
        rgba(0,0,0,0)
    );
}
  
.review {
    position: relative;
    flex: 0 0 auto;
    scroll-snap-align: center;
    width: clamp(16rem, 30vw, 30rem);
    border-radius: 20px;
    overflow: hidden;
    transition: transform 0.3s ease;
    background-color: var(--secondary-color-beige--);
    padding: clamp(.5rem, 1.5vw, 1.5rem);
    background-color: var(--secondary-color-beige--);
    display: flex;
    flex-direction: column;
    height: min-content;
    gap: clamp(1rem, 1.5vw, 1.5rem);
}

.review p{
    font-size:  clamp(1rem, 2vw, 1.3rem);
}


.review:hover {
    transform: translateY(-5px);
}

.review-image {
    border-radius: 50%;
    aspect-ratio: 1;
    width: 10%;
    height: auto;
    object-fit: contain;
}

.slider-controls {
    display: none;
    justify-content: space-between;
}

.slider-button {
    aspect-ratio: 1;
    width: 3rem;
    background-color: black;
    color: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 1rem;
    transition: background-color 0.3s ease;
}

.image-name{
    display: flex;
    align-items: center;
    gap: clamp(.5rem, 1vw, 1rem);
}

@media (max-width: 1200px) {
    .slider-controls {
        display: flex;
    }
    .reviews-slider{
        scroll-snap-type: x mandatory;
    }
    .review:hover {
        transform: none;
    }
}
