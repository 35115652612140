.about-me-container {
    padding: 5rem clamp(1rem, 2vw, 2rem) 1rem clamp(1rem, 2vw, 2rem);
    max-width: 90vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.about {
    display: flex;
    max-width: 100dvw;
    flex-direction: column;
    gap: clamp(1rem, 2vw, 2rem);
    text-align: left;
    padding: clamp(1.5rem, 2vw, 2rem);
    align-items: center;
    border-radius: 20px;
    line-height: 1.6;
    background-color: var(--secondary-color-blue--);
}

.about-heading > h1{
    text-align: center;
    font-size: var(--font-size-x-big--);
    max-width: 60%;
    min-width: fit-content;
}

.about-heading img{
    width: clamp(2rem, 3vw, 3rem);
    aspect-ratio: calc(222 / 188);
}

.about > div{
    display: flex;  
    flex-direction: column;
    gap: clamp(.75rem, 1.5vw, 1.5rem);
    padding: 0 clamp(1rem, 2vw, 2rem);
    border-radius: 20px;
    align-items: center;
}
@media (max-width: 425px) {
    a button{
        width: 80%!important;
    }
}

