.faq-container{
    padding: clamp(4rem, 10vw, 6rem) clamp(2rem, 3vw, 3rem) 0 clamp(2rem, 3vw, 3rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100dvw;
    gap: clamp(1.5rem, 2.5vw, 2.5rem);
}

.faq-container > div{
    display: flex;
    flex-direction: column;
    gap: clamp(.5rem, 1.25vw, 1.25rem);
    background-color: var(--secondary-color-blue--);
    border-radius: 20px;
    padding: clamp(.75rem, 1.5vw, 1.5rem);
    width: 100%;
}

.faq-container > div:nth-of-type(2n){
    background-color: var(--secondary-color-beige--);
}

.faq-question{
    font-family: 'Montserrat';
    font-weight: 800;
}