.recommendations > div:not(:first-of-type){
    display: flex;  
    flex-direction: column;
    gap: clamp(.75rem, 2vw, 2rem);
    background-color: var(--secondary-color-blue--);
    padding: clamp(1rem,  2.5vw, 2.5rem);
    border-radius: 20px;
    width: 85%;
    margin: 2.5rem auto 0 auto;
    justify-content: center;
}

.recommendations > div:first-of-type{
    display: flex;  
    flex-direction: column;
    gap: clamp(.75rem, 2vw, 2rem);
    justify-content: center;
    background-color: var(--secondary-color-blue--);
    padding: clamp(1rem,  2.5vw, 2.5rem);
    border-radius: 20px;
    width: 85%;
    margin: 2.5rem auto 0 auto;
}

.recommendations p{
    text-align: center;
    font-size: clamp(.9rem, 2vw, 1.35rem);
}

.recommendations h2{
    font-size: var(--font-size-big--);
    align-self: center;
}

.benefits-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(20rem, 1fr));
    row-gap: 4rem;
    column-gap: 2rem;
}

.benefits-grid > div{
    display: flex;
    align-items: center;
    gap: clamp(.75rem, 1.5vw, 1.5rem);
    flex-direction: row;
}

.social-proof{
    gap: clamp(2rem, 4vw, 4rem)!important;
    margin-bottom: clamp(2rem, 4vw, 4rem)!important;
    align-items: flex-start;
}

.social-proof p{
    position: relative;
    text-align: left;
}

@media (max-width: 800px) {
    .recommendations{
        align-items: center;
        text-align: center;
    }

    .benefits-grid{
        place-items: center;
        grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    }
}