body{
  overflow-x: hidden;
}

.courses {
  padding: clamp(1rem, 2vw, 2rem);
  max-width: 75vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.courses:has(.course:nth-of-type(1):last-child){
  max-width: 25rem;
}

.courses:has(.course:nth-of-type(2):last-child){
  max-width: 45rem;
}
  
.courses-header {
  text-align: center;
  margin: 1rem 0 2rem 0;
  width: 100vw;
  position: relative;
  gap: clamp(1rem, 2vw, 2rem);
  background-color: var(--secondary-color-beige--);
  padding: clamp(.75rem, 2vw, 2rem);
}
  
.courses-header h2 {
  font-size: clamp(1.2rem, 3vw, 2rem);
  margin-bottom: 1rem;
}
  
.courses-header p {
  font-size: var(--font-size-big--);
  font-family: var(--secondary-font--);
}
  
.courses-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr)); 
  row-gap: 2rem;
  column-gap: 2rem;
}
  
.course {
  background-color: #f4f4f4;
  border-radius: 20px;
  overflow: hidden;
  transition: transform 0.3s ease;
  font-size: var(--font-size-medium--);
  padding: .5rem;
  min-width: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
}
  
.course:hover {
  transform: translateY(-5px);
}
  
.course img {
  width: 100%;
  display: block;
  aspect-ratio: 1080 / 1350;
  border-radius: 20px;
}

.course h2{
  padding: 1rem;
}
  
.course a button{
  text-align: center;
  padding: 2rem;
  width: 100%;
  text-decoration: none;
  border-radius: 20px;
  font-size: var(--font-size-medium--);
  padding: 1rem;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  background-color: var(--secondary-color-blue--);
  transition: all .3s;
  font-family: var(--secondary-font--);
}

.course a button:hover{
  background-color: #8fbad8;
}

.courses {
  padding-top: 5rem; 
}
  
  