.contacts-container{
    padding: clamp(4rem, 10vw, 6rem) clamp(2rem, 3vw, 3rem) 0 clamp(2rem, 3vw, 3rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100dvw;
    gap: clamp(1.5rem, 2.5vw, 2.5rem);
}

.contacts-main{
    display: flex;  
    flex-direction: column;
    text-align: center;
    gap: clamp(1rem, 2vw, 2rem);
}

.contacts-main p{
    font-size: clamp(1.1rem, 1.75vw, 1.75rem);
}

.contacts-main h2{
    font-size: clamp(1.25rem, 2.25vw, 2.25rem);
}

a button{
    font-size: var(--font-size-medium--)!important;
}