.live-course-container {
    background-color: whitesmoke;
    padding: 2rem 2rem .25rem 2rem;
    text-align: center;
    width: 100%;
    margin: 0 auto 3rem auto;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.live-course-container > div{
    margin: 0 auto;
}
  
.course-info h3 {
    font-size: var(--font-size-big--);
    margin-bottom: 1rem;
}
  
.course-info p {
    color: #565656;
    font-size: var(--font-size-medium--);
}

.course-info p:last-child {
    margin-top: .5rem;
}
  
.timer {
    font-size: var(--font-size-big--);
    color: red;
    margin-top: 1.5rem;
}
  
.timer span {
    margin: 0 .3rem;
}

.time-up-message {
    margin-top: 1rem;
    font-size: var(--font-size-big--);
    color: #2ecc71;
    animation: fadeIn 1s ease-in;
}
  
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}